<template>
  <!--v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header-->
  <v-container style="max-height: 90vh; overflow-y: auto;">
    <v-row no-gutters>
      <v-col cols="12" id="GCU" class="my-3">

        <div class="title font-weight-bold">Charte de confidentialité</div>
      <!--/v-expansion-panel-header>

      <v-expansion-panel-content-->
        <div class="headline">1. Définition et nature des données à caractère personnel</div>

        <p>Lors de votre utilisation de notre site, nous pouvons être amenés à vous demander de
        nous communiquer des données à caractère personnel vous concernant.<br />
        Le terme « données à caractère personnel » désigne toutes les données qui permettent
        d’identifier un individu, ce qui correspond notamment à vos nom, prénoms, pseudonyme,
        photographie, adresses postale et de courrier électronique, numéros de téléphone, date
        de naissance, données relatives à vos transactions sur le site, détails de vos achats et
        abonnements, numéros de carte bancaire, ainsi qu’à tout autre renseignement que vous
          choisirez de nous communiquer à votre sujet.</p>

        <div class="headline">2. Objet de la présente charte</div>

        <p>La présente charte a pour objet de vous informer sur les moyens que nous mettons en
        œuvre pour collecter vos données à caractère personnel, dans le respect le plus strict
          de vos droits.</p>
        <p>Nous vous indiquons à ce sujet que nous nous conformons, dans la collecte et la gestion
        de vos données à caractère personnel, à la loi n° 78-17 du 6 janvier 1978 relative à
          l'informatique, aux fichiers et aux libertés, dans sa version actuelle.</p>

        <div class="headline">3. Identité du responsable de la collecte de données</div>

        <p>Le responsable de la collecte de vos données à caractère personnel est la société Roote SAS,
        immatriculée au Registre du Commerce et des Sociétés de Paris sous le n° 84100566300015,
          ayant son siège social au 128 Rue la Boétie 75008 Paris.</p>

        <div class="headline">4. Collecte des données à caractère personnel</div>

        <p>Vos données à caractère personnel sont collectées pour répondre à une ou plusieurs
          des finalités suivantes :</p>
        <ol>
          <li>Gérer votre accès à certains services accessibles sur le site et leur utilisation,</li>
          <li>Effectuer les opérations relatives à la gestion des clients concernant les
        contrats, commandes, livraisons, factures, programmes de fidélité, suivis de
            la relation avec les clients,</li>
          <li>Constituer un fichier de membres inscrits, d’utilisateurs, de clients et
            prospects,</li>
          <li>Adresser des newsletters, sollicitations et messages promotionnels. Dans le
        cas où vous ne le souhaiteriez pas, nous vous donnons la faculté d’exprimer
            votre refus à ce sujet lors de la collecte de vos données;</li>
          <li>Élaborer des statistiques commerciales et de fréquentation de nos services,</li>
          <li>Organiser des jeux concours, loteries et toutes opérations promotionnelles à
        l’exclusion des jeux d’argent et de hasard en ligne soumis à l’agrément de
            l’Autorité de Régulation des Jeux en ligne,</li>
        <li>Gérer la gestion des avis des personnes sur des produits, services ou
          contenus,</li>
          <li>Gérer les impayés et les contentieux éventuels quant à l’utilisation de nos
            produits et services,</li>
          <li>Respecter nos obligations légales et réglementaires.</li>
        </ol>
        <p>Nous vous informons, lors de la collecte de vos données personnelles, si certaines
        données doivent être obligatoirement renseignées ou si elles sont facultatives. Nous
        vous indiquons également quelles sont les conséquences éventuelles d’un défaut de
          réponse.</p>

        <div class="headline">5. Destinataires des données collectées</div>

        <p>Le personnel de notre société, les services chargés du contrôle (commissaire aux
        comptes notamment) et nos sous-traitants auront accès à vos données à caractère
          personnel.</p>
        <p>Peuvent également être destinataires de vos données à caractère personnel les
        organismes publics, exclusivement pour répondre à nos obligations légales, les
        auxiliaires de justice, les officiers ministériels et les organismes chargés d’effectuer le
          recouvrement de créances.</p>

        <div class="headline">6. Cession des données à caractère personnel</div>

        <p>Vos données à caractère personnel ne feront pas l’objet de cessions, locations ou
          échanges au bénéfice de tiers.</p>

        <div class="headline">7. Durée de conservation des données à caractère personnel</div>
        <ol>
          <li>Concernant les données relatives à la gestion de clients et prospects :
        Vos données à caractère personnel ne seront pas conservées au-delà de la durée
        strictement nécessaire à la gestion de notre relation commerciale avec vous. Toutefois,
        les données permettant d’établir la preuve d’un droit ou d’un contrat, devant être
        conservées au titre du respect d’une obligation légale, le seront pendant la durée prévue
        par la loi en vigueur.
        Concernant d’éventuelles opérations de prospection à destination des clients, leurs
        données pourront être conservées pendant un délai de trois ans à compter de la fin de la
        relation commerciale.
        Les données à caractère personnel relatives à un prospect, non client, pourront être
        conservées pendant un délai de trois ans à compter de leur collecte ou du dernier
        contact émanant du prospect.
        Au terme de ce délai de trois ans, nous pourrons reprendre contact avec vous pour
            savoir si vous souhaitez continuer à recevoir des sollicitations commerciales.</li>
          <li>Concernant les pièces d’identité :
        En cas d’exercice du droit d’accès ou de rectification, les données relatives aux pièces
        d’identité pourront être conservées pendant le délai prévu à l’article 9 du code de
        procédure pénale, soit un an. En cas d’exercice du droit d’opposition, ces données
        peuvent être archivées pendant le délai de prescription prévu par l’article 8 du code de
            procédure pénale, soit trois ans.</li>

        <li>Concernant les données relatives aux cartes bancaires :
        Les transactions financières relatives au paiement des achats et des frais via le site,
        sont confiées à un prestataire de services de paiement qui en assure le bon
        déroulement et la sécurité. (BNP Paribas)
        Pour les besoins des services, ce prestataire de services de paiement peut être amené
        à être destinataire de vos données à caractère personnel relatives à vos numéros de
        cartes bancaires, qu’il recueille et conserve en notre nom et pour notre compte.
        Nous n’avons pas accès à ces données.
        Pour vous permettre de réaliser régulièrement des achats ou de régler les frais afférents
        sur le site, vos données relatives à vos cartes bancaires sont conservées pendant le
        temps de votre inscription sur le site et à tout le moins, jusqu’au moment où vous
        réalisez votre dernière transaction.
        En ayant coché sur le site la case expressément prévue à cet effet, vous nous donnez
        votre consentement exprès pour cette conservation.
        Les données relatives au cryptogramme visuel ou CVV2, inscrit sur votre carte bancaire,
        ne sont pas stockées.
        Si vous refusez que vos données à caractère personnel relatives à vos numéros de
        cartes bancaires soient conservées dans les conditions précisées ci-dessus, nous ne
        conserverons pas ces données au-delà du temps nécessaire pour permettre la
        réalisation de la transaction.
        En tout état de cause, les données relatives à celles-ci pourront être conservées, pour
        une finalité de preuve en cas d’éventuelle contestation de la transaction, en archives
        intermédiaires, pour la durée prévue par l’article L 133-24 du Code monétaire et
        financier, en l’occurrence 13 mois suivant la date de débit. Ce délai peut être étendu à
        15 mois afin de prendre en compte la possibilité d’utilisation des cartes de paiement à
          débit différé.</li>
        <li>Concernant la gestion des listes d’opposition à recevoir de la prospection :
        Les informations permettant de prendre en compte votre droit d’opposition sont
          conservées au minimum trois ans à compter de l’exercice du droit d’opposition.</li>
        <li>Concernant les statistiques de mesure d’audience :
        Les informations stockées dans le terminal des utilisateurs ou tout autre élément utilisé
        pour identifier les utilisateurs et permettant leur traçabilité ou fréquentation ne seront pas
          conservées au-delà de 6 mois.</li>
        </ol>

        <div class="headline">8. Sécurité</div>
        <p>Nous vous informons prendre toutes précautions utiles, mesures organisationnelles et
        techniques appropriées pour préserver la sécurité, l’intégrité et la confidentialité de vos
        données à caractère personnel et notamment, empêcher qu’elles soient déformées,
        endommagées ou que des tiers non autorisés y aient accès. Nous recourrons ou
        pouvons recourir également à des systèmes de paiement sécurisé conformes à l’état de
          l’art et à la réglementation applicable.</p>


        <div class="headline">9. Cookies</div>

        <p>Les cookies sont des fichiers textes, souvent cryptés, stockés dans votre navigateur. Ils
        sont créés lorsque le navigateur d’un utilisateur charge un site internet donné : le site
        envoie des informations au navigateur, qui créé alors un fichier texte. Chaque fois que
        l’utilisateur revient sur le même site, le navigateur récupère ce fichier et l’envoie au
          serveur du site internet.</p>
        <p>On peut distinguer deux types de cookies, qui n’ont pas les mêmes finalités : les cookies
          techniques et les cookies publicitaires :</p>
        <ul>
        <li>Les cookies techniques sont utilisés tout au long de votre navigation, afin de la
        faciliter et d’exécuter certaines fonctions. Un cookie technique peut par exemple être
        utilisé pour mémoriser les réponses renseignées dans un formulaire ou encore les
        préférences de l’utilisateur s’agissant de la langue ou de la présentation d’un site
          internet, lorsque de telles options sont disponibles.</li>
        <li>Les cookies publicitaires peuvent être créés non seulement par le site internet sur
        lequel l’utilisateur navigue, mais également par d’autres sites internet diffusant des
        publicités, annonces, widgets ou autres éléments sur la page affichée. Ces cookies
        peuvent notamment être utilisés pour effectuer de la publicité ciblée, c’est-à-dire de
        la publicité déterminée en fonction de la navigation de l’utilisateur.
        Nous utilisons des cookies techniques. Ceux-ci sont stockés dans votre navigateur pour
        une période qui ne peut excéder six mois.
        Nous n’utilisons pas de cookies publicitaires. Toutefois, si nous devions en utiliser à
        l’avenir, nous vous en informerions au préalable et vous auriez la possibilité le cas
          échéant de désactiver ces cookies.</li>
        </ul>

        <p>Nous utilisons ou pouvons utiliser Google Analytics qui est un outil statistique d’analyse
        d’audience qui génère un cookie permettant de mesurer le nombre de visites sur le site,
        le nombre de pages vues et l’activité des visiteurs. Votre adresse IP est également
        collectée pour déterminer la ville depuis laquelle vous vous connectez. La durée de
        conservation de ce cookie est mentionnée à l’article 7 (v) de la présente charte.
        Nous vous rappelons à toutes fins utiles qu’il vous est possible de vous opposer au
        dépôt de cookies en configurant votre navigateur. Un tel refus pourrait toutefois
          empêcher le bon fonctionnement du site.</p>

        <div class="headline">10. Consentement</div>

        <p>Lorsque vous choisissez de communiquer vos données à caractère personnel, vous
        donnez expressément votre consentement pour la collecte et l’utilisation de celles-ci
          conformément à ce qui est énoncé à la présente charte et à la législation en vigueur.</p>

        <div class="headline">11. Accès à vos données à caractère personnel</div>
        <p>Conformément à la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et
        aux libertés, vous disposez du droit d’obtenir la communication et, le cas échéant, la
        rectification ou la suppression des données vous concernant, à travers un accès en
          ligne à votre dossier. Vous pouvez également vous adresser à :</p>
        <ul>
          <li>adresse de courrier électronique : contact@roote.io</li>
          <li>adresse de courrier postal : 128 Rue la Boétie 75008 Paris</li>
        </ul>
        <p>Il est rappelé que toute personne peut, pour des motifs légitimes, s'opposer au
          traitement des données la concernant.</p>

        <div class="headline">12. Modifications</div>
        <p>Nous nous réservons le droit, à notre seule discrétion, de modifier à tout moment la
        présente charte, en totalité ou en partie. Ces modifications entreront en vigueur à
        compter de la publication de la nouvelle charte. Votre utilisation du site suite à l’entrée
        en vigueur de ces modifications vaudra reconnaissance et acceptation de la nouvelle
        charte. A défaut et si cette nouvelle charte ne vous convient pas, vous ne devrez plus
          accéder au site.</p>

        <div class="headline">13. Entrée en vigueur</div>
        <p>La présente charte est entrée en vigueur le <span class="font-weight-bold">01/08/18</span>.
        </p>

      <!--/v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
name: "PrivacyPolicy"
}
</script>

<style scoped>

</style>